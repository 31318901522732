import { Box, Button, TextField, Fab } from "@mui/material";
import {
  selectedCitySelector,
  setSelectedCity,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddCityMutation,
  useGetCityQuery,
  useUpdateCityMutation,
  useGetAllCountriesQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";
import ComboBox from "../../../@jumbo/dz-components/autocomplete-cb/ComboBox";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";

import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

// import { AddCircle } from "@material-ui/icons";

const AddUpdateCity = ({ onClose, learning_journey_id, selectedId }) => {
  // const chatbot_types = ['scale', 'note', 'buddy'];
  const { showDialog, hideDialog } = useJumboDialog();

  const dispatch = useDispatch();
  const { fnShowSnackBar } = useSnackBarManager();

  const selectedCity = useSelector(selectedCitySelector);

  const { data: res_get_all } = useGetAllCountriesQuery();

  const { data: res_get } = useGetCityQuery(selectedCity?.id);

  const [updateCity, responseUpdate] = useUpdateCityMutation();
  const [addCity, responseAdd] = useAddCityMutation();

  const bluePrint = BLUE_PRINTS.city;

  const [cityState, setCityState] = useState(null);

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  const countries = res_get_all?.data?.countries?.map((country) =>
    BLUE_PRINTS.country.translate(country, selectedLanguage)
  );

  useEffect(() => {
    setCityState(
      selectedCity
        ? res_get?.data?.city
        : {
            ...bluePrint,
            country_id: res_get?.data?.city?.country_id ?? "",
          }
    );
  }, [res_get]);

  const addUpdateCityData = async (action) => {
    const formData = new FormData();
    formData.append("id", res_get?.data?.city?.id);

    formData.append("name", JSON.stringify(cityState.name));

    formData.append("country_id", cityState.country_id);

    const res = action === "add" ? await addCity(formData) : await updateCity(formData);
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };
  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />
      {cityState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                error={error?.country_id}
                value={cityState?.country_id || ""}
                data={countries}
                onChange={(e) => {
                  setCityState((x) => ({
                    ...x,
                    country_id: e.target.value,
                  }));
                }}
                placeholder="Select Country"
              />
            </Div>
          </Grid>

          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <MultiDZTextValidation
                  error={error?.[0]}
                  label="Name"
                  variant="outlined"
                  value={cityState?.name?.[selectedLanguage]}
                  onChange={(e) => {
                    setCityState((x) => ({
                      ...x,
                      name: { ...x.name, [selectedLanguage]: e.target.value },
                    }));
                  }}
                  placeholder="Name"
                />
              </Div>
            </Grid>
          </Grid>
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedCity(null));
              }}
            >
              Cancel
            </Button>
            {res_get?.data?.city?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateCityData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedCity(null));
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateCityData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedCity(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
          {/* dispatch(setSelectedCity(null)); */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateCity;
