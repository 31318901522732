import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import DZDropdown from "@jumbo/dz-components/dropdowns/DZDropdown";
import axios from "axios";
import Div from "@jumbo/shared/Div/Div";
import { Button, Grid, TextField, DialogActions } from "@mui/material";
import {
  BLUE_PRINTS,
  useAddHotelAirportPriceMutation,
  useGetAllAirportsQuery,
  useGetAllHotelsQuery,
  useGetAllRegionsQuery,
  useGetHotelAirportPriceMutation,
  useGetHotelFlightFormatQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import type { SchedulerHelpers } from "@aldabil/react-scheduler/types";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Config } from "./../../../constant/index";
import dayjs from "dayjs";
import Snackbar, {
  ErrorSnackbar,
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";

import Box from "@mui/material/Box";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

interface CustomEditorProps {
  scheduler: SchedulerHelpers;
}

const CustomEditor = ({
  scheduler,
  priceState1,
  setEvents,
  events,
}: CustomEditorProps) => {
  const event = scheduler.edited;
  const { fnShowSnackBar } = useSnackBarManager();
  const [startDate, setStartValue] = useState(
    dayjs(scheduler.state.start.value)
  );
  const [endDate, setEndValue] = useState(dayjs(scheduler.state.end.value));

  const handleStartDate = (startDate) => {
    setStartValue(startDate);
  };
  const handleEndDate = (endDate) => {
    setEndValue(endDate);
  };
  const [addHotelAirportPrice, responseAdd] = useAddHotelAirportPriceMutation();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [priceState, setPriceState] = useState(null);

  const validationRules = {
    flight_price: {
      required: true,
      isNumber: true,
    },
    hotel_price: {
      required: true,
      isNumber: true,
    },
  };

  const validateForm = () => {
    const errors = {};

    Object.keys(validationRules).forEach((field) => {
      const rules = validationRules[field];
      if (rules.required && !priceState?.[field]) {
        errors[field] = "This field is required";
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length == 0;
  };

  const handleSubmit = async () => {
    try {
      const isValid = validateForm();
      if (isValid) {
        await addHotelAirportPriceData();
      } else {
        console.log("Form validation failed:", formErrors);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const totalPrice =
    parseFloat(priceState?.hotel_price || 0) +
    parseFloat(priceState?.flight_price || 0);

  function getDatesBetweenArray(startDate, endDate) {
    const dates = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function getDatesBetween(startDate, endDate) {
    const dates = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const datesBetween = getDatesBetween(startDate, endDate);
  const datesBetweenArray = getDatesBetweenArray(startDate, endDate);

  const eventPromises = datesBetween.map(async (date) => {
    return {
      title: totalPrice,
      start: date,
      end: date,
    };
  });

  const addHotelAirportPriceData = async (action) => {
    const formData = new FormData();
    formData.append("hotel_id", priceState1?.hotel_id);
    formData.append("airport_id", priceState1?.airport_id);
    formData.append("hotel_price", priceState?.hotel_price);
    formData.append("flight_price", priceState?.flight_price);
    datesBetweenArray.forEach((date, index) => {
      formData.append(`dates[${index}]`, date);
    });
    const res = await addHotelAirportPrice(formData);

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
    const added_updated_events = await Promise.all(eventPromises);
    setEvents((prev) => [...prev, ...added_updated_events]);
    scheduler.onConfirm(added_updated_events, event ? "edit" : "create");
    scheduler.close();
  };

  return (
    <div>
      {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} />}
      <Snackbar
        responseAdd={responseAdd?.isSuccess === true ? responseAdd : false}
      />
      <div style={{ padding: "1rem" }}>
        {/* <p>Load your custom form/fields</p> */}
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 0.5, width: "34ch" },
          }}
          autoComplete="off"
        >
          <div>
            <TextField
              fullWidth
              {...(formErrors?.hotel_price && {
                error: true,
                helperText: formErrors?.hotel_price,
              })}
              label="Hotel Price"
              type="number"
              inputProps={{ min: 1 }}
              variant="outlined"
              noOfRows={1}
              value={priceState?.hotel_price}
              onChange={(e) => {
                setPriceState((x) => ({
                  ...x,
                  hotel_price: e.target.value,
                }));
              }}
            />
            <TextField
              fullWidth
              {...(formErrors?.flight_price && {
                error: true,
                helperText: formErrors?.flight_price,
              })}
              label="Flight Price"
              type="number"
              inputProps={{ min: 1 }}
              variant="outlined"
              noOfRows={1}
              value={priceState?.flight_price}
              onChange={(e) => {
                setPriceState((x) => ({
                  ...x,
                  flight_price: e.target.value,
                }));
              }}
            />
          </div>
        </Box>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 0.5, width: "34ch" },
          }}
          autoComplete="off"
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Start"
                value={startDate}
                onChange={(newValue) => handleStartDate(newValue.$d)}
              />
              <DatePicker
                label="End"
                value={endDate}
                onChange={(newValue) => handleEndDate(newValue.$d)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </div>
      <DialogActions>
        <Button onClick={scheduler.close}>Cancel</Button>
        <Button
          onClick={() => {
            handleSubmit();
            setShowBackDrop(true);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </div>
  );
};
const PricesLists = () => {
  const { data: res_get_all_airports } = useGetAllAirportsQuery();
  const { data: res_get_all_hotels } = useGetAllHotelsQuery();
  const [addHotelAirportPrice, responseAdd] = useAddHotelAirportPriceMutation();
  const [getHotelAirportPrice, responseGet] = useGetHotelAirportPriceMutation();
  const { data: res_get_all_regions } = useGetAllRegionsQuery();
  const { data, isLoading, isError, refetch } = useGetHotelFlightFormatQuery();
  const bluePrint = BLUE_PRINTS.price;
  const [errors, setErrors] = useState({});
  const [openError, setOpenError] = useState(false);
  const [priceState, setPriceState] = useState(null);
  const [priceDate, setPriceDate] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();
  const [selectedFile, setSelectedFile] = useState();
  const addHotelAirportPriceData = async (action) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("hotel_id", priceState?.hotel_id);
    formData.append("airport_id", priceState?.airport_id);
    const res = await addHotelAirportPrice(formData);
    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };

  const filehandle = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
    }
  };
  const getHotelAirportPriceData = async () => {
    const newErrors = validateHotelData();
    setErrors(newErrors);
    if (!isEmpty(newErrors)) {
      setOpenError(true);
      return;
    }
    setShowCalendar(false);

    const formData = new FormData();
    // formData.append("image", selectedFile);

    formData.append("hotel_id", priceState?.hotel_id);
    formData.append(
      "airport_id",
      priceState?.airport_id ? priceState.airport_id : 3
    );

    const result = await getHotelAirportPrice(formData);
    if (result?.data?.message) {
      fnShowSnackBar(result?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(result?.error?.data?.message,"error");
    }
    setPriceDate(result?.data?.data?.prices);
    setShowCalendar(true);
  };
  const [events, setEvents] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);

  // Update the events state when you receive data
  useEffect(() => {
    // Assuming you receive the data with dates in priceState
    if (priceDate) {
      // Create an array of events with the dates

      const eventsWithData = priceDate?.map((data) => ({
        title: data?.hotel_price + data?.flight_price,
        start: new Date(data?.date), // Convert date string to Date object
        end: new Date(data?.date), // Convert date string to Date object
        // Add any other event properties you need
      }));

      // Set the events state with the new data
      setEvents(eventsWithData);
    }
  }, [priceDate]);

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        Config.serverUrl +
          "api/admin/download-hotel-airport-prices?nocache=" +
          Date.now(),
        {
          responseType: "blob", // Important to specify the response type as blob
        }
      );

      // Create a blob URL to download the file
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "hotel_Flight_Price_Format.xlsx";
      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const { showDialog, hideDialog } = useJumboDialog();
  const addFlightPrice = (menuitem) => {
    showDialog({
      variant: "confirm",
      title: "Are you sure about Adding Flight Prices?",
      showLanguageSwitcher: false,
      onYes: () => {
        addHotelAirportPriceData();
        setShowBackDrop(true);
      },
      onNo: hideDialog,
    });
  };
  console.log(events, "events");
  const regions = res_get_all_regions?.data?.regions?.map((region) => region);

  const hotels = res_get_all_hotels?.data?.hotels?.filter((hotel) =>
    hotel?.region_hotels?.some(
      (regionHotel) => regionHotel?.region_id == priceState?.region_id
    )
  );
  const selectedHotelAirports =
    res_get_all_hotels?.data?.hotels.find(
      (hotel) => hotel.id == priceState?.hotel_id
    )?.hotel_airports || [];

  const airports = res_get_all_airports?.data?.region_airports?.filter(
    (airport) => airport?.id == selectedHotelAirports?.[0]?.airport_id
  );

  // const airports = res_get_all_airports?.data?.region_airports?.filter((airport) =>
  //   airport?.airport_regions?.some((regionAirport) => regionAirport?.region_id == priceState?.region_id)
  // );
  // const airports = res_get_all_hotels?.data?.hotels?.hotel_airports?.map((airport) =>airport.id);

  // const hotels = res_get_all_hotels?.data?.hotels?.map((hotel) => hotel);

  // const hotels = res_get_all_hotels?.data?.hotels?.filter((hotel) => hotel?.region_id==priceState?.hotel_id);
  const validateHotelData = () => {
    let newErrors = {};
    if (isEmpty(priceState?.region_id)) {
      newErrors = { ...newErrors, region: "Select Region" };
    }
    if (isEmpty(priceState?.hotel_id)) {
      newErrors = { ...newErrors, hotel: "Select Hotel" };
    }
    if (isEmpty(priceState?.airport_id)) {
      newErrors = { ...newErrors, airport: "Select Airport" };
    }

    return newErrors;
  };
  function isEmpty(value) {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }
    return false;
  }
  function getFirstErrorMessage(errors) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        return errors[key];
      }
    }
    return "No errors found";
  }
  return (
    <>
      {openError && (
        <ErrorSnackbar
          error={getFirstErrorMessage(errors)}
          setOpenError={setOpenError}
        />
      )}
      {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} />}
      <Snackbar
        responseAdd={responseAdd?.isSuccess === true ? responseAdd : false}
      />
      <JumboDemoCard
        title="Prices"
        wrapperSx={{
          p: 10,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5, pb: 20 }}
      >
        <Grid container spacing={3.75}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "16px",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0,0,0,0.05)",
              }}
            >
              <h2
                style={{ fontSize: "24px", fontWeight: "500", color: "#333" }}
              >
                Upload Flight Prices
              </h2>

              <Button
                variant="contained"
                sx={{ width: "fit-content" }}
                onClick={() => {
                  handleDownload();
                }}
              >
                Download Format
              </Button>

              <TextField
                fullWidth
                type="file"
                inputProps={{ min: 1 }}
                onChange={filehandle}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "16px",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0,0,0,0.05)",
              }}
            >
              <h2
                style={{ fontSize: "24px", fontWeight: "500", color: "#333" }}
              >
                Get Hotel & Airport Prices
              </h2>

              {/* <div style={{ display: 'flex', gap: '16px' }}> */}

              <DZDropdown
                placeholder={"Select Region"}
                error={errors?.region}
                value={priceState?.region_id || ""}
                data={regions}
                fieldTextName={"title"}
                onChange={(e) => {
                  setPriceState((x) => ({ ...x, region_id: e.target.value }));
                }}
              />

              <DZDropdown
                placeholder={"Select Hotel"}
                error={errors?.hotel}
                value={priceState?.hotel_id || ""}
                data={hotels}
                onChange={(e) => {
                  setPriceState((x) => ({ ...x, hotel_id: e.target.value }));
                }}
              />

              {/* </div> */}

              <DZDropdown
                placeholder={"Select Airport"}
                error={errors?.airport}
                value={priceState?.airport_id || ""}
                data={airports}
                onChange={(e) => {
                  setPriceState((x) => ({ ...x, airport_id: e.target.value }));
                }}
              />

              <Button
                variant="contained"
                sx={{ width: "fit-content" }}
                onClick={() => {
                  getHotelAirportPriceData();
                }}
              >
                Get Price
              </Button>
            </div>

            <Button
              variant="contained"
              onClick={() => {
                addFlightPrice();
                // resetCount();
              }}
            >
              Add
            </Button>
          </div>

          {showCalendar && (
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <Scheduler
                  customEditor={(scheduler) => (
                    <CustomEditor
                      scheduler={scheduler}
                      priceState1={priceState}
                      setEvents={setEvents}
                      events={events}
                    />
                  )}
                  events={events}
                  view="month"
                  day={null}
                  today={null}
                  month={{
                    weekDays: [0, 1, 2, 3, 4, 5, 6],
                    weekStartOn: 6,
                    startHour: 9,
                    endHour: 17,
                    navigation: true,
                    disableGoToDay: false,
                  }}
                  week={null}
                  viewerExtraComponent={(fields, event) => {
                    return (
                      <div>
                        <p>Useful to render custom fields...</p>
                        <p>Description: {event.description || "Nothing..."}</p>
                      </div>
                    );
                  }}
                />
              </Div>
            </Grid>
          )}
        </Grid>
      </JumboDemoCard>
    </>
  );
};

export default PricesLists;
