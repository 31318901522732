import { createSlice } from '@reduxjs/toolkit';


export const slice = createSlice({
    name: 'SelectedStuffReducer',
    initialState: {
        selectedGallery: null,
        selectedHotel: null,
        selectedSport: null,
        selectedLeadServiceType: null,
        selectedHotelSport: null,
        selectedAirport: null,
        selectedRegion: null,
        selectedCountry: null,
        selectedCity: null,
        selectedLeadService: null,
        selectedPage: null,
        selectedReference: null,
        selectedAdmin: null,
        selectedLeadUser: null,
        selectedLead: null,
        selectedLeadOffer: null,
        selectedLeadContract: null,
        selectedLeadContractCondition: null,
        selectedContractConditionType: null,
        selectedChatId: null,

        selectedFaqs: null,

        selectedCategory: null,
        selectedUserLogin: null,
        selectedProduct: null,
        selectedAccessory: null,
        selectedProductAccessory: null,
        selectedUser: null,

        selectedRestaurant: null,
        selectedSizeGroup: null,
        selectedSize: null,




    },
    reducers: {
        setSelectedUserLogin: (state, action) => {
            state.selectedUserLogin = action.payload;
        },
        setSelectedHotel: (state, action) => {
            state.selectedHotel = action.payload;
        },
        setSelectedSport: (state, action) => {
            state.selectedSport = action.payload;
        },
        setSelectedLeadServiceType: (state, action) => {
            state.selectedLeadServiceType = action.payload;
        },
        setSelectedGallery: (state, action) => {
            state.selectedGallery = action.payload;
        },
        setSelectedHotelSport: (state, action) => {
            state.selectedHotelSport = action.payload;
        },
        setSelectedAirport: (state, action) => {
            state.selectedAirport = action.payload;
        },
        setSelectedRegion: (state, action) => {
            state.selectedRegion = action.payload;
        },
        setSelectedCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
        setSelectedCity: (state, action) => {
            state.selectedCity = action.payload;
        },
        setSelectedLeadService: (state, action) => {
            state.selectedLeadService = action.payload;
        },
        setSelectedPage: (state, action) => {
            state.selectedPage = action.payload;
        },

        setSelectedFaqs: (state, action) => {
            state.selectedFaqs = action.payload;
        },

        setSelectedReference: (state, action) => {
            state.selectedReference = action.payload;
        },
        setSelectedLead: (state, action) => {
            state.selectedLead = action.payload;
        },
        setSelectedLeadOffer: (state, action) => {
            state.selectedLeadOffer = action.payload;
        },
        setSelectedLeadContract: (state, action) => {
            state.selectedLeadContract = action.payload;
        },
        setSelectedLeadContractCondition: (state, action) => {
            state.selectedLeadContractCondition = action.payload;
        },
        setSelectedContractConditionType: (state, action) => {
            state.selectedContractConditionType = action.payload;
        },
        setSelectedLeadUser: (state, action) => {
            state.selectedLeadUser = action.payload;
        },
        setSelectedAdmin: (state, action) => {
            state.selectedAdmin = action.payload;
        },
        setSelectedChatId: (state, action) => {
            state.selectedChatId = action.payload;
        },




        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setSelectedProduct: (state, action) => {
            state.selectedProduct = action.payload;
        },

        setSelectedAccessory: (state, action) => {
            state.selectedAccessory = action.payload;
        },
        setSelectedProductAccessory: (state, action) => {
            state.selectedProductAccessory = action.payload;
        },
        setSelectedRestaurant: (state, action) => {
            state.selectedRestaurant = action.payload;
        },

        setSelectedUser: (state, action) => {
            console.log("redux data", action)
            state.selectedUser = action.payload;
        },

        setSelectedSizeGroup: (state, action) => {
            console.log("redux data", action)
            state.selectedSizeGroup = action.payload;
        },
        setSelectedSize: (state, action) => {
            state.selectedSize = action.payload;
        },
        //old


        // setSelectedUser: (state, action) => {
        //     state.selectedUser = action.payload;
        // },
        resetSelectedStuffReducer: (state) => {
            state.selectedUserLogin = null;
            state.selectedSport = null;
            state.selectedHotelSport = null;
            state.selectedHotel = null;
            state.selectedAirport = null;
            state.selectedGallery = null;
            state.selectedRegion= null;
            state.selectedCountry= null;
            state.selectedCity= null;
            state.selectedLeadService= null;
            state.selectedLeadServiceType= null;
            state.selectedPage= null;
            state.selectedReference= null;
            state.selectedAdmin= null;
            state.selectedLeadUser= null;
            state.selectedLead= null;
            state.selectedLeadOffer= null;
            state.selectedLeadContract= null;
            state.selectedLeadContractCondition= null;
            state.selectedContractConditionType= null;
          
            state.selectedFaqs = null;

            state.selectedCategory = null;
            state.selectedProduct = null;
            state.selectedAccessory = null;
            state.selectedProductAccessory = null;
            state.selectedUser = null;
            state.selectedSizeGroup = null;
            state.selectedSize = null;


        },
    },
});

export const {
    setSelectedUserLogin,
    setSelectedSport,
    setSelectedHotelSport,
    setSelectedHotel,
    setSelectedAirport,
    setSelectedRegion,
    setSelectedCountry,
    setSelectedCity,
    setSelectedLeadService,
    setSelectedLeadServiceType,
    setSelectedPage,
    setSelectedReference,
    setSelectedAdmin,
    setSelectedLeadUser,
    setSelectedLead,
    setSelectedLeadOffer,
    setSelectedLeadContract,
    setSelectedLeadContractCondition,
    setSelectedContractConditionType,
    setSelectedGallery,
    setSelectedChatId,

    setSelectedFaqs,


    //old
    setSelectedCategory,
    setSelectedProduct,
    setSelectedAccessory,
    setSelectedProductAccessory,
    setSelectedRestaurant,
    setSelectedUser,

    setSelectedSizeGroup,
    setSelectedSize,

} = slice.actions;
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const selectedGallerySelector = (state) => state.SelectedStuffReducer.selectedGallery;
export const selectedUserLoginSelector = (state) => state.SelectedStuffReducer.selectedUserLogin;
export const selectedHotelSelector = (state) => state.SelectedStuffReducer.selectedHotel;
export const selectedSportSelector = (state) => state.SelectedStuffReducer.selectedSport;
export const selectedLeadServiceTypeSelector = (state) => state.SelectedStuffReducer.selectedLeadServiceType;
export const selectedHotelSportSelector = (state) => state.SelectedStuffReducer.selectedHotelSport;
export const selectedAirportSelector = (state) => state.SelectedStuffReducer.selectedAirport;
export const selectedRegionSelector = (state) => state.SelectedStuffReducer.selectedRegion;
export const selectedCountrySelector = (state) => state.SelectedStuffReducer.selectedCountry;
export const selectedCitySelector = (state) => state.SelectedStuffReducer.selectedCity;
export const selectedLeadServiceSelector = (state) => state.SelectedStuffReducer.selectedLeadService;
export const selectedPageSelector = (state) => state.SelectedStuffReducer.selectedPage;
export const selectedReferenceSelector = (state) => state.SelectedStuffReducer.selectedReference;
export const selectedLeadSelector = (state) => state.SelectedStuffReducer.selectedLead;
export const selectedLeadOfferSelector = (state) => state.SelectedStuffReducer.selectedLeadOffer;
export const selectedLeadContractSelector = (state) => state.SelectedStuffReducer.selectedLeadContract;
export const selectedLeadContractConditionSelector = (state) => state.SelectedStuffReducer.selectedLeadContractCondition;
export const selectedContractConditionTypeSelector = (state) => state.SelectedStuffReducer.selectedContractConditionType;
export const selectedLeadUserSelector = (state) => state.SelectedStuffReducer.selectedLeadUser;
export const selectedAdminSelector = (state) => state.SelectedStuffReducer.selectedAdmin;
export const selectedChatIdSelector = (state) => state.SelectedStuffReducer.selectedChatId;

export const selectedFaqsSelector = (state) => state.SelectedStuffReducer.selectedFaqs;

//olg
export const selectedCategorySelector = (state) => state.SelectedStuffReducer.selectedCategory;
export const selectedProductSelector = (state) => state.SelectedStuffReducer.selectedProduct;
export const selectedAccessorySelector = (state) => state.SelectedStuffReducer.selectedAccessory;
export const selectedProductAccessorySelector = (state) => state.SelectedStuffReducer.selectedProductAccessory;
export const selectedRestaurantSelector = (state) => state.SelectedStuffReducer.selectedRestaurant;
export const selectedUserSelector = (state) => state.SelectedStuffReducer.selectedUser;
export const selectedSizeGroupSelector = (state) => state.SelectedStuffReducer.selectedSizeGroup;
export const selectedSizeSelector = (state) => state.SelectedStuffReducer.selectedSize;
//old



export default slice.reducer;