import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  List,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tab,
  InputAdornment,
  Card,
  CardContent,
  Alert,
  AlertTitle,
} from "@mui/material";
import JumboScrollbar from "@jumbo/components/JumboScrollbar/JumboScrollbar";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSelector, useDispatch } from "react-redux";
import {
  LANGUAGES,
  selectedLanguageSelector,
} from "app/rkt_query/SettingsReducer";
import Snackbar, {
  ErrorSnackbar,
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useLocation } from "react-router-dom";

import TranslateIcon from "@mui/icons-material/Translate";

import {
  Box,
  Button,
  TextField,
  Fab,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import {
  selectedHotelSelector,
  setSelectedHotel,
} from "app/rkt_query/SelectedStuffReducer";

import {
  BLUE_PRINTS,
  useAddHotelMutation,
  useGetAllSportsQuery,
  useGetAllAirportsQuery,
  useGetHotelQuery,
  useUpdateHotelMutation,
  useGetAllCountriesQuery,
  useGetAllCitiesQuery,
  useDeleteHotelAttributeMutation,
  useGetAllRegionsQuery,
} from "app/rkt_query/storeApis";

import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";

import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";

import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import { Config } from "constant";
import DZLabel, {
  DzNoBackgroundLabel,
  DzNoBackgroundTranslatable,
} from "@jumbo/dz-components/labels/DZLabel";

import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import DZDropdownMultiple, {
  DZDropdownMultipleLanguage,
  DZDropdownMultipleNew,
} from "@jumbo/dz-components/dropdowns/DZDropdownMultiple";
import {
  StandaloneSearchBox,
  LoadScript,
  useLoadScript,
} from "@react-google-maps/api";
import DZAutoCompleteAddress from "@jumbo/dz-components/textboxes/DZAutoCompleteAddress";
import DZDeleteDialog from "@jumbo/dz-components/dialog/DZDeleteDialog";
import DzTextEditorMultilingual from "@jumbo/dz-components/TextEditor/DzTextEditorMultilingual";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 24,
  height: 48,
  width: 48,
  borderRadius: "50%",
  minWidth: 42,
  marginRight: 16,
  padding: theme.spacing(1),
  alignItems: "center",
  justifyContent: "center",
  border: `solid 1px ${theme.palette.divider}`,
}));

const UserProfileTab = ({ user, buddy_groups }) => {
  const sources = ["YouTube", "Dailymotion", "Vimeo"];
  const [errors, setErrors] = useState({});
  const [openError, setOpenError] = useState(false);

  // console.log(buddy_groups?.group[1]);
  const [value, setValue] = React.useState("general");
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [deleteHotelAttributes, responseDelete] =
    useDeleteHotelAttributeMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedHotel = useSelector(selectedHotelSelector);
  const [inputFields, setInputFields] = useState([
    { id: "", name: LANGUAGES.defaultObject, show: "" },
  ]);
  const { fnShowSnackBar } = useSnackBarManager();
  const [airportPrices, setAirportPrices] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [index1, setIndex] = React.useState(null);

  const { data: res_get } = useGetHotelQuery(location?.state?.hotelId);

  const [updateHotel, responseUpdate] = useUpdateHotelMutation();
  const [addHotel, responseAdd] = useAddHotelMutation();
  const { data: use_get_all_airports } = useGetAllAirportsQuery();
  const { data: use_get_all_sports } = useGetAllSportsQuery();
  const { data: use_get_all_countries } = useGetAllCountriesQuery();
  const { data: use_get_all_cities } = useGetAllCitiesQuery();
  const { data: use_get_all_regions } = useGetAllRegionsQuery();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const bluePrint = BLUE_PRINTS.hotel;

  const [hotelState, setHotelState] = useState(null);
  const [editorState, setEditorState] = useState(null);

  const [showBackDrop, setShowBackDrop] = useState(false);

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  const defaultAirport = res_get?.data?.hotel?.hotel_airports?.find(
    (airportPrice) => airportPrice?.price == null
  );
  const defaultAirportId = defaultAirport?.airport_id ?? null;
  const sportIds = res_get?.data?.sports?.map((sport) => sport?.id);

  const regionIds = res_get?.data?.hotel?.region_hotels?.map((region) => Number(region?.region_id));


  console.log(regionIds, "res_get?.data?.hotel");
  useEffect(() => {
    setHotelState(
      res_get?.data?.hotel
        ? {
            ...res_get?.data?.hotel,
            night_temp: res_get?.data?.hotel?.weather?.night_temp ?? null,
            day_temp: res_get?.data?.hotel?.weather?.day_temp ?? null,
            title: res_get?.data?.hotel?.weather?.title ?? null,
            web_link: res_get?.data?.hotel?.weather?.web_link ?? null,
            airport_address: res_get?.data?.hotel?.airport_address ?? null,
            map_address: res_get?.data?.hotel?.map_address ?? null,
            sports_address: res_get?.data?.hotel?.sports_address ?? null,
            sports_id: sportIds ?? [],
            regions_id: regionIds ?? [],
            airports_id: res_get?.data?.airports ?? [],
            airportPrices:
              res_get?.data?.hotel?.hotel_airports.map(
                (airportPrice) => airportPrice?.price 
              ) ?? [],
            defaultAirport: defaultAirportId,
          }
        : {
            ...bluePrint,
            country_id: res_get?.data?.hotel?.country_id ?? "",
            city_id: res_get?.data?.hotel?.city_id ?? "",
          }
    );

    setInputFields(
      res_get?.data?.hotel?.hotel_attributes ?? [
        { id: "", name: LANGUAGES.defaultObject, show: "" },
      ]
    );

    setEditorState(
      res_get?.data?.hotel?.heading_description?.[selectedLanguage]
    );
  }, [res_get]);
  function isEmpty(value) {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }
    return false;
  }
  const validateHotelData = () => {
    let newErrors = {};

    if (isEmpty(hotelState.name)) {
      newErrors = { ...newErrors, name: "Please enter hotel name" };
    }
    if (isEmpty(hotelState.country_id)) {
      newErrors = { ...newErrors, country_id: "Please enter country" };
    }
    if (isEmpty(hotelState.city_id)) {
      newErrors = { ...newErrors, city_id: "Please enter city" };
    }
    if (isEmpty(hotelState.lat) || isEmpty(hotelState.long)) {
      newErrors = {
        ...newErrors,
        lat: "Please enter hotel address",
        long: "Please enter hotel address",
      };
    }
    if (isEmpty(hotelState.airport_lat) || isEmpty(hotelState.airport_long)) {
      newErrors = {
        ...newErrors,
        airport_lat: "Please enter airport address",
        airport_long: "Please enter airport address",
      };
    }
    if (isEmpty(hotelState.sports_lat) || isEmpty(hotelState.sports_long)) {
      newErrors = {
        ...newErrors,
        sports_lat: "Please enter sport address",
        sports_long: "Please enter sport address",
      };
    }
    if (isEmpty(hotelState.sports_id)) {
      newErrors = { ...newErrors, sports_id: "Please select sports" };
    }
    if (isEmpty(hotelState.regions_id)) {
      newErrors = { ...newErrors, regions_id: "Please select regions" };
    }
    if (isEmpty(hotelState.airports_id)) {
      newErrors = { ...newErrors, airports_id: "Please select airport" };
    }
    if (
      getFirstErrorMessage(newErrors) == "Please enter airport address" ||
      getFirstErrorMessage(newErrors) == "Please enter sport address" ||
      getFirstErrorMessage(newErrors) == "Please enter hotel address"
    ) {
      setValue("weather");
    } else {
      setValue("general");
    }
    return newErrors;
  };
  const addUpdateHotelData = async (action) => {
    const newErrors = validateHotelData();
    setErrors(newErrors);
    if (!isEmpty(newErrors)) {
      setOpenError(true);
      return;
    }

    const formData = new FormData();
    formData.append("id", res_get?.data?.hotel?.id);
    formData.append("country_id", hotelState.country_id);
    formData.append("sports_id", JSON.stringify(hotelState.sports_id));
    formData.append("regions_id", JSON.stringify(hotelState.regions_id));
    formData.append("airports_id", JSON.stringify(hotelState.airports_id));
    formData.append("city_id", hotelState.city_id);
    formData.append("name", hotelState.name);
    formData.append("slug", hotelState.slug);
    formData.append("home_page_show", hotelState.home_page_show);
    formData.append("lat", hotelState.lat == null ? "" : hotelState.lat);
    formData.append("long", hotelState.long == null ? "" : hotelState.long);
    if (
      hotelState.detail?.en ||
      hotelState.detail?.de ||
      hotelState.detail?.sv ||
      hotelState.detail?.da ||
      hotelState.detail?.no
    ) {
      formData.append("detail", JSON.stringify(hotelState.detail));
    } else {
      formData.append(
        "detail",
        JSON.stringify({ en: "", da: "", sv: "", de: "", no: "" })
      );
    }
    // formData.append("detail", JSON.stringify(hotelState.detail));

    formData.append("stars", hotelState.stars);
    formData.append("map_address", hotelState.map_address);
    formData.append("sports_address", hotelState.sports_address);
    formData.append("airport_address", hotelState.airport_address);
    formData.append("center_distance", hotelState.center_distance || 0);
    formData.append("sports_distance", hotelState.sports_distance || 0);
    formData.append("airport_distance", hotelState.airport_distance || 0);
    formData.append(
      "sports_lat",
      hotelState.sports_lat == null ? "" : hotelState.sports_lat
    );
    formData.append(
      "sports_long",
      hotelState.sports_long == null ? "" : hotelState.sports_long
    );
    formData.append(
      "airport_long",
      hotelState.airport_long == null ? "" : hotelState.airport_long
    );
    formData.append(
      "airport_lat",
      hotelState.airport_lat == null ? "" : hotelState.airport_lat
    );
    formData.append("day_temp", hotelState?.day_temp);
    formData.append("night_temp", hotelState?.night_temp);
    formData.append("video", hotelState.video);
    formData.append("source", hotelState.source || "");
    formData.append("title", hotelState.title);
    formData.append("web_link", hotelState.web_link);
    formData.append("defaultAirport", hotelState.defaultAirport);
    formData.append("hotelAttributes", JSON.stringify(inputFields) || []);
    formData.append("airportPrices", JSON.stringify(hotelState?.airportPrices));

    const res = action === "add"
      ? await addHotel(formData)
          .unwrap()
          .then((payload) => {
            navigate("/hotels", { replace: true });
          })
          .catch((error) => {})
      : await updateHotel(formData)
          .unwrap()
          .then((payload) => {
            navigate("/hotels", { replace: true });
          })
          .catch((error) => {});

          
    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message|| "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }

  };
  const sports = use_get_all_sports?.data?.sports?.map((sport) => sport);
  const countries = use_get_all_countries?.data?.countries?.map((country) =>
    BLUE_PRINTS.country.translate(country, selectedLanguage)
  );
  // const cities = use_get_all_cities?.data?.cities?.filter((city) => city?.country_id == hotelState?.country_id);
  const cities = (use_get_all_cities?.data?.cities || [])
    ?.filter((city) => city?.country_id == hotelState?.country_id)
    ?.map((city) => BLUE_PRINTS.city.translate(city, selectedLanguage));
  const airports = use_get_all_airports?.data?.region_airports?.map(
    (airport) => airport
  );
  const regions = use_get_all_regions?.data?.regions?.map((region) => region);
  const inputRef = useRef();
  const inputAirportRef = useRef();
  const inputFacilityRef = useRef();

  const handleFormChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    setInputFields(
      inputFields.map((eng, i) =>
        i === index
          ? {
              ...eng,
              [name]:
                type == "checkbox"
                  ? checked
                    ? 1
                    : 0
                  : eng?.[name].en === undefined
                  ? value
                  : { ...eng?.[name], [selectedLanguage]: value },
            }
          : eng
      )
    );
  };

  const addFields = () => {
    setInputFields([
      ...inputFields,
      { id: "", name: LANGUAGES.defaultObject, show: 0 },
    ]);
  };
  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const deleteHotelSportAttributes = (id, index) => {
    deleteHotelAttributes(id);
    removeFields(index);
    setOpenSnackbar(true);
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
    setOpenSnackbar(false);
  };
  const handleClickOpen = (index) => {
    setOpen(true);
    setIndex(index);
  };

  const isAirportSelected = (airport, airports_id) => {
    return airports_id.some(
      (item) => item.id === airport.id && item.name === airport.name
    );
  };
  // console.log("sports_id", responseDelete, openSnackbar);
  function getFirstErrorMessage(errors) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        return errors[key];
      }
    }
    return "No errors found";
  }
  return (
    <>
      {openError && (
        <ErrorSnackbar
          error={getFirstErrorMessage(errors)}
          setOpenError={setOpenError}
        />
      )}
      {/* {!isEmpty(errors) && (
        <Alert variant="filled" severity="error" severity="error">
          <AlertTitle>Error</AlertTitle>
          {getFirstErrorMessage(errors)}
        </Alert>
      )} */}
      {responseDelete?.isSuccess ? (
        <NoHideSnackbar
          responseAdd={responseDelete?.isSuccess === true ? responseDelete : ""}
        />
      ) : (
        ""
      )}
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />
      <Grid container spacing={3.75}>
        <Grid item xs={12} md={12} lg={12}>
          <JumboCardQuick title={"Hotel"} noWrapper>
            <TabContext value={value}>
              <TabList
                sx={{ borderBottom: 1, borderColor: "divider" }}
                onChange={(event, newValue) => setValue(newValue)}
              >
                <Tab
                  label={"General"}
                  value={"general"}
                  sx={{ flex: "1 1 auto" }}
                />
                <Tab
                  label={"Properties"}
                  value={"property"}
                  sx={{ flex: "1 1 auto" }}
                />
                <Tab
                  label={"Weather Distance"}
                  value={"weather"}
                  sx={{ flex: "1 1 auto" }}
                />
              </TabList>
              <TabPanel value="general" sx={{ p: 0 }}>
                <JumboScrollbar
                  autoHeight
                  autoHeightMin={790}
                  autoHide
                  autoHideDuration={200}
                  autoHideTimeout={500}
                >
                  <List
                    disablePadding
                    sx={{
                      // display: 'flex',
                      flexWrap: "wrap",
                      margin: (theme) => theme.spacing(0, 1),
                    }}
                  >
                    <div>
                      {hotelState ? (
                        <div
                          style={{
                            marginTop: 25,
                            display: "flex",
                            flexDirection: "column",
                            gap: 25,
                            marginBottom: 60,
                          }}
                        >
                          <Grid container spacing={3.75}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Div sx={{ mb: 0.5, mt: 1 }}>
                                <DZTextValidation
                                  error={error?.name || errors?.name}
                                  label="Hotel Name"
                                  variant="outlined"
                                  value={hotelState?.name}
                                  onChange={(e) => {
                                    setHotelState((x) => ({
                                      ...x,
                                      name: e.target.value,
                                    }));
                                  }}
                                />
                              </Div>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                              <Div sx={{ mb: 0.5, mt: 1 }}>
                                <TextField
                                  fullWidth
                                  label="Star"
                                  type="number"
                                  inputProps={{ min: 1 }}
                                  variant="outlined"
                                  noOfRows={1}
                                  value={hotelState?.stars}
                                  {...(error?.stars && {
                                    error: true,
                                    helperText: error?.stars,
                                  })}
                                  onChange={(e) => {
                                    setHotelState((x) => ({
                                      ...x,
                                      stars: e.target.value,
                                    }));
                                  }}
                                />
                              </Div>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                              <Div sx={{ mb: 0.5, mt: 1 }}>
                                <DZTextValidation
                                  error={error?.slug}
                                  label="Slug"
                                  variant="outlined"
                                  value={hotelState?.slug}
                                  onChange={(e) => {
                                    setHotelState((x) => ({
                                      ...x,
                                      slug: e.target.value,
                                    }));
                                  }}
                                />
                              </Div>
                            </Grid>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                boxShadow:
                                  "rgba(94, 59, 183, 0.082) 0px 4px 30px",
                                border: "1px solid rgb(179 179 179)",
                                width: "100%",
                                marginLeft: "30px",
                                marginTop: "10px",
                                padding: "10px",
                                borderRadius: "6px",
                              }}
                            >
                              <Grid item xs={12} md={12} lg={12}>
                                <Div sx={{ mb: 0.5, mt: 1 }}>
                                  <DzNoBackgroundLabel
                                    title={
                                      "Regions (Region Passenger can go to this hotel, mostly avaialbe for al lregions):"
                                    }
                                  />
                                </Div>
                              </Grid>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "spaceBetween",
                                }}
                              >
                                {regions?.map((region) => (
                                  <Grid
                                    item
                                    xs={5}
                                    md={2}
                                    lg={2}
                                    key={region?.id}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={hotelState?.regions_id?.includes(
                                            region?.id
                                          )}
                                          name={region?.language}
                                          onChange={(e) => {
                                            const checked = e.target.checked;
                                            setHotelState((prevState) => ({
                                              ...prevState,
                                              regions_id: checked
                                                ? [
                                                    ...prevState?.regions_id,
                                                    region?.id,
                                                  ]
                                                : prevState?.regions_id.filter(
                                                    (id) => id !== region?.id
                                                  ),
                                            }));
                                          }}
                                        />
                                      }
                                      label={region?.language}
                                    />
                                  </Grid>
                                ))}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                boxShadow:
                                  "rgba(94, 59, 183, 0.082) 0px 4px 30px",
                                border: "1px solid rgb(179 179 179)",
                                width: "100%",
                                marginLeft: "30px",
                                marginTop: "10px",
                                padding: "10px",
                                borderRadius: "6px",
                              }}
                            >
                              <Grid item xs={12} md={12} lg={12}>
                                <Div sx={{ mb: 0.5, mt: 1 }}>
                                  <DzNoBackgroundLabel title={"Sports"} />
                                </Div>
                              </Grid>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "spaceBetween",
                                }}
                              >
                                {sports?.map((sport) => (
                                  <Grid
                                    item
                                    xs={5}
                                    md={2}
                                    lg={2}
                                    key={sport?.id}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={hotelState?.sports_id.includes(
                                            sport?.id
                                          )}
                                          name={sport?.name[selectedLanguage]}
                                          onChange={(e) => {
                                            const checked = e.target.checked;
                                            setHotelState((prevState) => ({
                                              ...prevState,
                                              sports_id: checked
                                                ? [
                                                    ...prevState?.sports_id,
                                                    sport?.id,
                                                  ]
                                                : prevState?.sports_id.filter(
                                                    (id) => id !== sport?.id
                                                  ),
                                            }));
                                          }}
                                        />
                                      }
                                      label={sport?.name[selectedLanguage]}
                                    />
                                  </Grid>
                                ))}
                              </div>
                            </div>

                            {/* </Grid> */}

                            {/* <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>
                          {airports?.map((airport) => (
                                <div key={airport?.id}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={hotelState?.airports_id.includes(airport.id)}
                                        name={airport?.name}
                                        onChange={(e) => {
                                          const checked = e.target.checked;
                                          setHotelState((prevState) => ({
                                            ...prevState,
                                            airports_id: checked
                                              ? [...prevState?.airports_id, airport?.id]
                                              : prevState?.airports_id.filter((id) => id !== airport?.id),
                                          }));
                                        }}
                                      />
                                    }
                                    label={airport?.name}
                                  />
                                </div>
                              ))}
                              </Div>
                          </Grid> */}
                            {/*   <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>
                              <DZDropdownMultipleNew
                                placeholder={"Select Airports"}
                                label="Select Airports"
                                value={hotelState?.airports_id}
                                data={airports}
                                onChange={(e) => {
                                  setHotelState((x) => ({
                                    ...x,
                                    airports_id: e.target.value,
                                  }));
                                }}
                              />
                            </Div>
                          </Grid> */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                boxShadow:
                                  "rgba(94, 59, 183, 0.082) 0px 4px 30px",
                                border: "1px solid rgb(179 179 179)",
                                width: "100%",
                                marginLeft: "30px",
                                marginTop: "10px",
                                padding: "10px",
                                borderRadius: "6px",
                              }}
                            >
                              <Grid item xs={12} md={12} lg={12}>
                                <Div sx={{ mb: 0.5, mt: 1 }}>
                                  <DzNoBackgroundLabel title={"Airports"} />
                                </Div>
                              </Grid>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "spaceBetween",
                                }}
                              >
                                {airports?.map((airport) => (
                                  <Grid
                                    item
                                    xs={5}
                                    md={2}
                                    lg={2}
                                    key={airport?.id}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={isAirportSelected(
                                            airport,
                                            hotelState?.airports_id
                                          )}
                                          name={airport?.name}
                                          onChange={(e) => {
                                            const checked = e.target.checked;
                                            setHotelState((prevState) => ({
                                              ...prevState,
                                              airports_id: checked
                                                ? [
                                                    ...prevState?.airports_id,
                                                    airport,
                                                  ]
                                                : prevState?.airports_id.filter(
                                                    (item) =>
                                                      item?.id !==
                                                        airport?.id ||
                                                      item?.name !==
                                                        airport?.name
                                                  ),
                                            }));
                                          }}
                                        />
                                      }
                                      label={airport?.name}
                                      required
                                    />
                                  </Grid>
                                ))}
                              </div>
                            </div>

                            {hotelState?.airports_id &&
                            hotelState.airports_id.length > 0 ? (
                              <Grid item xs={12} md={12} lg={12}>
                                <Div sx={{ mb: 0.5, mt: 1 }}>
                                  <Card
                                    sx={{
                                      overflow: "visible",
                                      mt: 4,
                                      border: 1,
                                    }}
                                  >
                                    <CardContent>
                                      <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={hotelState?.defaultAirport}
                                        onChange={(e) => {
                                          setHotelState((x) => ({
                                            ...x,
                                            defaultAirport: e.target.value,
                                          }));
                                        }}
                                      >
                                        {hotelState?.airports_id?.map(
                                          (input, index) => {
                                            return (
                                              <>
                                                <Grid container spacing={3.75}>
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    lg={6}
                                                  >
                                                    <Div
                                                      sx={{ mb: 0.5, mt: 1 }}
                                                    >
                                                      <FormControlLabel
                                                        value={input?.id}
                                                        control={<Radio />}
                                                        label={input?.name}
                                                      />
                                                    </Div>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    lg={6}
                                                  >
                                                    <Div
                                                      sx={{ mb: 0.5, mt: 1 }}
                                                    >
                                                      {hotelState?.defaultAirport !=
                                                        input?.id && (
                                                        <TextField
                                                          fullWidth
                                                          label="Price"
                                                          type="number"
                                                          inputProps={{
                                                            min: 1,
                                                          }}
                                                          name="price"
                                                          variant="outlined"
                                                          noOfRows={1}
                                                          value={
                                                            hotelState
                                                              ?.airportPrices[
                                                              index
                                                            ] || 0
                                                          }
                                                          onChange={(e) => {
                                                            const newPrices = [
                                                              ...hotelState?.airportPrices,
                                                            ];
                                                            newPrices[index] =
                                                              e.target.value;
                                                            setHotelState(
                                                              (x) => ({
                                                                ...x,
                                                                airportPrices:
                                                                  newPrices,
                                                              })
                                                            );
                                                          }}
                                                        />
                                                      )}
                                                    </Div>
                                                  </Grid>
                                                </Grid>
                                              </>
                                            );
                                          }
                                        )}
                                      </RadioGroup>
                                    </CardContent>
                                  </Card>
                                </Div>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid item xs={12} md={6} lg={6}>
                              <Div sx={{ mb: 0.5, mt: 1 }}>
                                <DZDropdown
                                  placeholder={"Select Country"}
                                  label="Select Country"
                                  value={hotelState?.country_id || ""}
                                  error={
                                    error?.country_id || errors?.country_id
                                  }
                                  data={countries}
                                  onChange={(e) => {
                                    setHotelState((x) => ({
                                      ...x,
                                      country_id: e.target.value,
                                    }));
                                  }}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <Div sx={{ mb: 0.5, mt: 1 }}>
                                <DZDropdown
                                  placeholder={"Select City"}
                                  label="Select City"
                                  value={hotelState?.city_id || ""}
                                  error={error?.city_id || errors?.city_id}
                                  data={cities}
                                  onChange={(e) => {
                                    setHotelState((x) => ({
                                      ...x,
                                      city_id: e.target.value,
                                    }));
                                  }}
                                />
                              </Div>
                            </Grid>
                          </Grid>

                          {/* <TextEditor data={hotelState?.detail}  onChange={(e) => { setHotelState((x) => ({ ...x, detail:  e.target.value ,  })) /> */}
                          {/* <Grid item xs={12} md={12} lg={12}>
             <Div sx={{ mb: 0.5, mt: 1 }}>
               <DZTextValidation
                 error={error?.[0]}
                 label="Hotel Heading"
                 variant="outlined"
                 value={hotelState?.heading_title?.[selectedLanguage]}
                 onChange={(e) => {
                   setHotelState((x) => ({
                     ...x,
                     heading_title: { ...x.heading_title, [selectedLanguage]: e.target.value },
                   }));
                 }}
               />
             </Div>
           </Grid> */}
                          <DzTextEditorMultilingual
                            data={hotelState?.detail?.[selectedLanguage]}
                            onChange={(newContent) => {
                              setHotelState((x) => ({
                                ...x,
                                detail: {
                                  ...x.detail,
                                  [selectedLanguage]: newContent,
                                },
                              }));
                            }}
                          />

                          {/* <div style={dialogButtonsStyle}>
           <Button
             variant="outlined"
             onClick={() => {
              
               dispatch(setSelectedHotel(null));
             }}
           >
             Cancel
           </Button>
           {res_get?.data?.hotel?.id ? (
             <Button
               variant="contained"
               onClick={() => {
                 addUpdateHotelData();
                 setShowBackDrop(true);
                 resetCount();
                 dispatch(setSelectedHotel(null));
               }}
             >
               Update
             </Button>
           ) : (
             <Button
               variant="contained"
               onClick={() => {
                 addUpdateHotelData("add");
                 setShowBackDrop(true);
                 resetCount();
                 dispatch(setSelectedHotel(null));
               }}
             >
               Add
             </Button>
           )}
         </div> */}
                          {/* dispatch(setSelectedHotel(null)); */}
                        </div>
                      ) : (
                        <div>Loading....</div>
                      )}
                    </div>
                  </List>
                </JumboScrollbar>
              </TabPanel>
              <TabPanel value="property" sx={{ p: 0 }}>
                <JumboScrollbar
                  autoHeight
                  autoHeightMin={600}
                  autoHide
                  autoHideDuration={200}
                  autoHideTimeout={500}
                >
                  <List
                    disablePadding
                    sx={{
                      // display: 'flex',
                      flexWrap: "wrap",
                      margin: (theme) => theme.spacing(0, 1),
                    }}
                  >
                    <div
                      style={{
                        marginTop: 25,
                        display: "flex",
                        flexDirection: "column",
                        gap: 25,
                        marginBottom: 60,
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Grid container spacing={3.75}>
                          <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>
                              <DZTextValidation
                                error={error?.[0]}
                                label="Video"
                                variant="outlined"
                                value={hotelState?.video}
                                onChange={(e) => {
                                  setHotelState((x) => ({
                                    ...x,
                                    video: e.target.value,
                                  }));
                                }}
                              />
                            </Div>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>
                              <DZSimpleDropDown
                                placeholder={"Select Source"}
                                value={hotelState?.source}
                                data={sources}
                                onChange={(e) => {
                                  setHotelState((x) => ({
                                    ...x,
                                    source: e.target.value,
                                  }));
                                }}
                              />
                            </Div>
                          </Grid>
                        </Grid>

                        <DZLabel title={"Hotel Attributes"} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                          }}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ControlPointOutlinedIcon />}
                            onClick={addFields}
                          >
                            Add More
                          </Button>
                        </div>
                      </div>

                      <form>
                        {inputFields?.map((input, index) => {
                          return (
                            <Card
                              sx={{ overflow: "visible", mt: 4, border: 1 }}
                            >
                              <CardContent>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "end",
                                    width: "100%",
                                  }}
                                >
                                  {index1 === index ? (
                                    <DZDeleteDialog
                                      title={
                                        "Are you sure about deleting this Attribute?"
                                      }
                                      description={
                                        " You won't be able to recover this attribute later"
                                      }
                                      openBox={open}
                                      handleClose={() => handleClose()}
                                      handleDeleteFunctionCall={() =>
                                        deleteHotelSportAttributes(
                                          input?.id,
                                          index
                                        )
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}

                                  {input?.id ? (
                                    <Button
                                      variant="outlined"
                                      startIcon={<DeleteIcon />}
                                      onClick={() => handleClickOpen(index)}
                                    >
                                      Delete
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      startIcon={<DeleteIcon />}
                                      sx={{ color: "red" }}
                                      onClick={() => removeFields(index)}
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </div>
                                <div key={index}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={input?.show}
                                        name="show"
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                      />
                                    }
                                    label="Is Show"
                                  />
                                  <Grid item xs={12} md={6} lg={6}>
                                    <Div sx={{ mb: 0.5, mt: 1 }}>
                                      <input
                                        type="hidden"
                                        name="id"
                                        value={input?.id}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                      />

                                      <TextField
                                        fullWidth
                                        label="Title"
                                        type="text"
                                        name="name"
                                        variant="outlined"
                                        noOfRows={1}
                                        value={input.name?.[selectedLanguage]}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <TranslateIcon />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Div>
                                  </Grid>
                                </div>
                              </CardContent>
                            </Card>
                          );
                        })}
                      </form>
                    </div>
                  </List>
                </JumboScrollbar>
              </TabPanel>
              <TabPanel value="weather" sx={{ p: 0 }}>
                <JumboScrollbar
                  autoHeight
                  autoHeightMin={600}
                  autoHide
                  autoHideDuration={200}
                  autoHideTimeout={500}
                >
                  <List
                    disablePadding
                    sx={{
                      // display: 'flex',
                      flexWrap: "wrap",
                      margin: (theme) => theme.spacing(0, 1),
                    }}
                  >
                    <div
                      style={{
                        marginTop: 25,
                        display: "flex",
                        flexDirection: "column",
                        gap: 25,
                        marginBottom: 60,
                      }}
                    >
                      <Grid container spacing={3.75}>
                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <DZAutoCompleteAddress
                              onLoad={(ref) => (inputRef.current = ref)}
                              label="Enter Hotel Address"
                              error={errors?.lat || errors?.long}
                              name="hotel_address"
                              showValue={hotelState?.map_address}
                              handlePlaceChanged={(e) => {
                                const [place] = inputRef.current.getPlaces();
                                setHotelState((x) => ({
                                  ...x,
                                  lat: place.geometry.location.lat(),
                                  long: place.geometry.location.lng(),
                                  map_address: place.formatted_address,
                                }));
                              }}
                              onChange={(e) => {
                                const newAddress = e.target.value;
                                setHotelState((x) => ({
                                  ...x,
                                  map_address: newAddress,
                                }));
                              }}
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <DZAutoCompleteAddress
                              onLoad={(ref) => (inputAirportRef.current = ref)}
                              label="Enter Airport Address"
                              name="airport_address"
                              error={
                                errors?.airport_lat || errors?.airport_long
                              }
                              showValue={hotelState?.airport_address}
                              handlePlaceChanged={(e) => {
                                const [place] =
                                  inputAirportRef.current.getPlaces();
                                setHotelState((x) => ({
                                  ...x,
                                  airport_lat: place.geometry.location.lat(),
                                  airport_long: place.geometry.location.lng(),
                                  airport_address: place?.formatted_address,
                                }));
                              }}
                              onChange={(e) => {
                                const newAddress = e.target.value;
                                setHotelState((x) => ({
                                  ...x,
                                  airport_address: newAddress,
                                }));
                              }}
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <DZAutoCompleteAddress
                              onLoad={(ref) => (inputFacilityRef.current = ref)}
                              label="Enter Facility Address"
                              name="sports_address"
                              showValue={hotelState?.sports_address}
                              error={errors?.sports_lat || errors?.sports_long}
                              handlePlaceChanged={(e) => {
                                const [place] =
                                  inputFacilityRef.current.getPlaces();
                                console.log(
                                  place?.formatted_address,
                                  "place.address_components?.[0]?.long_name"
                                );

                                setHotelState((x) => ({
                                  ...x,
                                  sports_lat: place.geometry.location.lat(),
                                  sports_long: place.geometry.location.lng(),
                                  sports_address: place?.formatted_address,
                                }));
                              }}
                              onChange={(e) => {
                                const newAddress = e.target.value;
                                setHotelState((x) => ({
                                  ...x,
                                  sports_address: newAddress,
                                }));
                              }}
                            />
                          </Div>
                        </Grid>
                        <br />
                        <Grid item xs={6} md={4} lg={4}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <TextField
                              fullWidth
                              label="Distance Airport"
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    KM
                                  </InputAdornment>
                                ),
                              }}
                              variant="outlined"
                              noOfRows={1}
                              value={hotelState?.airport_distance}
                              onChange={(e) => {
                                setHotelState((x) => ({
                                  ...x,
                                  airport_distance: e.target.value,
                                }));
                              }}
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={6} md={4} lg={4}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <TextField
                              fullWidth
                              label="Distance Sport"
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    KM
                                  </InputAdornment>
                                ),
                              }}
                              variant="outlined"
                              noOfRows={1}
                              value={hotelState?.sports_distance}
                              onChange={(e) => {
                                setHotelState((x) => ({
                                  ...x,
                                  sports_distance: e.target.value,
                                }));
                              }}
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={6} md={4} lg={4}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <TextField
                              fullWidth
                              label="Distance Center"
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    KM
                                  </InputAdornment>
                                ),
                              }}
                              variant="outlined"
                              noOfRows={1}
                              value={hotelState?.center_distance}
                              onChange={(e) => {
                                setHotelState((x) => ({
                                  ...x,
                                  center_distance: e.target.value,
                                }));
                              }}
                            />
                          </Div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <DZTextValidation
                              error={error?.[0]}
                              label="Day Temperature"
                              variant="outlined"
                              value={hotelState?.day_temp}
                              onChange={(e) => {
                                setHotelState((x) => ({
                                  ...x,
                                  day_temp: e.target.value,
                                }));
                              }}
                            />
                            <p style={{ color: "silver" }}>
                              e.g 34,34,34,34 for jan to dec
                            </p>
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <DZTextValidation
                              error={error?.[0]}
                              label="Night Temperature"
                              variant="outlined"
                              value={hotelState?.night_temp}
                              onChange={(e) => {
                                setHotelState((x) => ({
                                  ...x,
                                  night_temp: e.target.value,
                                }));
                              }}
                            />
                            <p style={{ color: "silver" }}>
                              e.g 34,34,34,34 for jan to dec
                            </p>
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <DZTextValidation
                              error={error?.[0]}
                              label="Title"
                              variant="outlined"
                              value={hotelState?.title}
                              onChange={(e) => {
                                setHotelState((x) => ({
                                  ...x,
                                  title: e.target.value,
                                }));
                              }}
                            />
                            <p style={{ color: "silver" }}>
                              weather detail website name.
                            </p>
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <DZTextValidation
                              error={error?.[0]}
                              label="Link To Website"
                              variant="outlined"
                              value={hotelState?.web_link}
                              onChange={(e) => {
                                setHotelState((x) => ({
                                  ...x,
                                  web_link: e.target.value,
                                }));
                              }}
                            />
                          </Div>
                        </Grid>
                      </Grid>
                    </div>
                  </List>
                </JumboScrollbar>
              </TabPanel>
            </TabContext>
          </JumboCardQuick>
        </Grid>
        <div style={dialogButtonsStyle}>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(setSelectedHotel(null));
              navigate("/hotels");
            }}
          >
            Back
          </Button>
          {res_get?.data?.hotel?.id ? (
            <Button
              variant="contained"
              onClick={() => {
                addUpdateHotelData();
                setShowBackDrop(true);
                resetCount();
                dispatch(setSelectedHotel(null));
              }}
            >
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                addUpdateHotelData("add");
                setShowBackDrop(true);
                resetCount();
                dispatch(setSelectedHotel(null));
              }}
            >
              Add
            </Button>
          )}
        </div>
      </Grid>
    </>
  );
};

export default UserProfileTab;
