import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import Accordion from '@jumbo/components/MuiAccordian';
import React, { useEffect, useState } from 'react'
import {
  useGetAllLeadOffersQuery, BLUE_PRINTS, useGetLeadOfferQuery, useDeleteLeadOfferMutation,useSendLeadOfferMutation, useGetLeadQuery, useDeleteLeadContractMutation, useSendLeadContractMutation, useViewLeadOfferMutation, useViewLeadContractMutation, useViewContractDetailMutation, useViewLeadPassengerMutation,
  // useGetAllCompaniesQuery
} from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import AddUpdateLeadOffer from './AddUpdateLeadOffer';
import { setSelectedLeadContract, setSelectedLeadOffer } from 'app/rkt_query/SelectedStuffReducer';
import ViewLeadOffer from './ViewLeadOffer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';

// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
import { Box } from '@mui/material';
import { IconButton, Button, Typography } from '@mui/material';
import AddUpdateLeadContract from '../lead_contract/AddUpdateLeadContract';
import ViewLeadContract from '../lead_contract/ViewLeadContract';
import { Config } from 'constant';
import ChangePassenger from '../change passenger/ChangePassenger';





const LeadOfferList = () => {

  const location = useLocation();


  const [type, setType] = useState()

  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteLeadOffer, responseDelete] = useDeleteLeadOfferMutation();
  const [viewLeadOffer, responseView] = useViewLeadOfferMutation();
  const [viewLeadContract, responseViewContract] = useViewLeadContractMutation();
  const [viewContractDetail, responseContractDetail] = useViewContractDetailMutation();
  const [viewLeadPassenger, responseLeadPassenger] = useViewLeadPassengerMutation();
  const [deleteLeadContract, responseDeleteContract] = useDeleteLeadContractMutation();
  const [sendLeadOffer, responseLeadOffer] = useSendLeadOfferMutation();
  const [sendLeadContract, responseLeadContract] = useSendLeadContractMutation();
 
  const { data: res_get_lead } = useGetAllLeadOffersQuery(location?.state?.lead_id);
  const [showMode, setShowMode] = useState(false);
 
  const bluePrint = BLUE_PRINTS.leadOffer
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedLeadOffer(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedLeadOffer(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedLeadOffer(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedLeadOffer(row)); }
  bluePrint.handleDelete = (id) => { deleteLeadOffer(id); }
  bluePrint.handleSend = (id) => { sendLeadOffer({id:id}); }
  bluePrint.handleGoTo = (data) => {setType("lead_offer");
  window.open(Config.frontendUrl+'lead-offer/'+data?.row?.slug+"?admin=yes", '_blank');
  // viewLeadOffer(data?.row?.slug);
 }
  const bluePrintContract = BLUE_PRINTS.leadContract
  bluePrintContract.addRecord = () => { setShowMode(false); dispatch(setSelectedLeadContract(null)); }
  bluePrintContract.showRecord = (row) => { setShowMode(true); dispatch(setSelectedLeadContract(row)); }
  bluePrintContract.editRecord = (row) => { setShowMode(false); dispatch(setSelectedLeadContract(row)); }
  bluePrintContract.deleteRecord = (row) => { dispatch(setSelectedLeadContract(row)); }
  bluePrintContract.handleDelete = (id) => { deleteLeadContract(id); }
  bluePrintContract.handleSend = (id) => { sendLeadContract({id:id}); }
  bluePrintContract.handleGoTo = (data) => { setType(data?.title);
    
    switch (data?.title) {
      
      case "offer-contract":
        window.open(Config.frontendUrl+"lead-contract/"+data?.row?.slug+"?admin=yes", '_blank');
        // checkSlug(data?.row?.slug);    
        break;
      case "contract-passenger":
        // viewLeadPassenger(data?.row?.slug);
        window.open(Config.frontendUrl+'contract-passenger/'+data?.row?.slug+"?admin=yes", '_blank');

        break;
      case "change-passenger":
        showDialog({
          title: "Update Passenger",
          showLanguageSwitcher:false,
          content: <ChangePassenger slug={res_get_lead?.data?.lead?.slug} />
        });

        break;
      case "add-contract-detail":
        // viewContractDetail(data?.row?.slug);
        window.open(Config.frontendUrl+data?.row?.slug+"?admin=yes", '_blank');


        break;
    
      default:
        console.log("no link");
        break;
    }
    
    }



// // get all contracts of each offer
const allContracts = res_get_lead?.data?.lead?.offers?.reduce((contract, offer) => {
  return contract.concat(offer.contract);
}, []);

const checkSlug=()=>{
  switch (type) {
      
    case "lead_offer":
    
    window.open(responseView.data?.data?.lead_offer_url, '_blank');
  

      break;
    case "offer-contract":
   


    // Open a new browser tab or window and display the response data
    window.open(responseViewContract.data?.data?.lead_contract_url, '_blank');
  
      break;
    
      case "add-contract-detail":
  


    // Open a new browser tab or window and display the response data
    window.open(responseContractDetail.data?.data?.contract_passenger_url, '_blank');
  
      break;
      case "contract-passenger":



    // Open a new browser tab or window and display the response data
    window.open(responseLeadPassenger.data?.data?.lead_passenger_url, '_blank');

      break;
  
    default:
      console.log("no link");
      break;
  }
}
// useEffect(() => {
// console.log(type);

//   switch (type) {
      
//     case "lead_offer":
//      if(responseView.isSuccess){
//     window.open(responseView.data?.data?.lead_offer_url, '_blank');
//   }

//       break;
//     case "offer-contract":
//       if(responseViewContract.isSuccess){


//     // Open a new browser tab or window and display the response data
//     window.open(responseViewContract.data?.data?.lead_contract_url, '_blank');
//   }
//       break;
    
//       case "add-contract-detail":
//      if(responseContractDetail.isSuccess){


//     // Open a new browser tab or window and display the response data
//     window.open(responseContractDetail.data?.data?.contract_passenger_url, '_blank');
//   }
//       break;
//       case "contract-passenger":
//     if(responseLeadPassenger.isSuccess){


//     // Open a new browser tab or window and display the response data
//     window.open(responseLeadPassenger.data?.data?.lead_passenger_url, '_blank');
//   }
//       break;
  
//     default:
//       console.log("no link");
//       break;
//   }




 
// }, [responseView,responseViewContract,responseContractDetail,responseLeadPassenger])


  return (
    <>
     <JumboDemoCard
          title={"Lead and User Detail"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
    <Accordion />
    </div>
    </JumboDemoCard>
    <br/>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      {responseDeleteContract.isLoading === true && <SimpleBackdrop responseAdd={responseDeleteContract} />}
      {responseDeleteContract?.isSuccess === true && <Snackbar responseAdd={responseDeleteContract} />}
      {responseLeadOffer.isLoading === true && <SimpleBackdrop responseAdd={responseLeadOffer} />}
      {responseLeadOffer?.isSuccess === true && <Snackbar responseAdd={responseLeadOffer} />}
      {responseLeadContract.isLoading === true && <SimpleBackdrop responseAdd={responseLeadContract} />}
      {responseLeadContract?.isSuccess === true && <Snackbar responseAdd={responseLeadContract} />}
      {/* <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button> */}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
        <JumboDemoCard
          title={"LeadOffer"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="LeadOffer"
              btnTitleAdd1="Lead Contract"
              translate={true}
              ddTranslate={false}
              data={res_get_lead?.data?.lead?.offers}
              bluePrint={bluePrint}
              ViewModel={() => <ViewLeadOffer />}
              ModalContent={() => <AddUpdateLeadOffer onClose={hideDialog}   lead_id={location?.state?.lead_id}   />}
              ModalRelationContent={() => <AddUpdateLeadContract onClose={hideDialog}    />}
              showAddButton={false}
              leadSendTitle="Send"
              showHideLeadOfferSendTitle={false}
              showAddButtonContract={false}
              addContractTitle="Add Contract"
              showHideLeadSlug={false}
              hideViewBtn={false}
              leadSlug="View Lead Offer"
              // showHideLeadSlug={false}
           
            />
           
          </div>
        </JumboDemoCard>
      </Box>

     {allContracts && <Box sx={{ display: 'flex',marginTop:20, flexDirection: 'column', gap: 7 }}>
        <JumboDemoCard
          title={"Lead Offer Contracts"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="LeadContract"
              translate={true}
              ddTranslate={false}
              data={allContracts}
              bluePrint={bluePrintContract}
              ViewModel={() => <ViewLeadContract />}
              ModalContent={() => <AddUpdateLeadContract onClose={hideDialog}   />}
              showAddButton={false}
              HeaderAddButton={false}
              leadSendTitle="Send"
              showHideLeadOfferSendTitle={false}
              showHideLeadSlug={false}
              showHideContractPassengerSlug={false}
              showHideAddContractDetail={false}
              addContractDetailTitle="Add Contract Detail"
              contractPassengerTitle="Passenger List"
              leadSlug="View Lead Contract"
              hideViewBtn={false}
              showHideChangePassenger={false}
              changePassengerTitle="Change Passenger"
              
              // showHideLeadSlug={false}
           
            />
           
          </div>
        </JumboDemoCard>
      </Box>}
    </>
  )
}


export default LeadOfferList