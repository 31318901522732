import React, { useState } from "react";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CategoryIcon from '@mui/icons-material/Category';
import AppsIcon from '@mui/icons-material/Apps';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FormatListNumberedRtlOutlinedIcon from '@mui/icons-material/FormatListNumberedRtlOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { ROLE } from "app/rkt_query/SettingsReducer";
import useCheckRoles from "@jumbo/dz-components/custom-hooks/useCheckRoles";
import { decryptStoredData } from "app/pages/storetoken/StoreTokenCheck";
import { Config } from "constant";
import { selectedUserLoginSelector } from "app/rkt_query/SelectedStuffReducer";
import { useSelector } from "react-redux";
import RoomServiceIcon from '@mui/icons-material/RoomService';
import KayakingIcon from '@mui/icons-material/Kayaking';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import PublicIcon from '@mui/icons-material/Public';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CollectionsIcon from '@mui/icons-material/Collections';
import PagesIcon from '@mui/icons-material/Pages';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ChecklistIcon from '@mui/icons-material/Checklist';
import HvacIcon from '@mui/icons-material/Hvac';

// useCheckRoles()
//  const GetFromRedux = () => {
//     const [allowances, setAllowances] = useState([]);
//  const roleName= useSelector(selectedUserLoginSelector)
//  setAllowances(roleName)  
//  return  allowances;

//     }
export const checkRole = (status) => {
    switch (status) {
        case ROLE.Admin:
        return ROLE.Admin;
      case ROLE.Manager:
        return ROLE.Manager;
      case ROLE.Receptionist:
        return ROLE.Receptionist;
      default:
        return ROLE.Receptionist;
      
    }
  };
  
//   const role=decryptStoredData(Config.roleName)
  
  export const adminManagerCondition=()=>{
//   const role_name=GetFromRedux();
    return (true);
    // return (checkRole(role) === ROLE.Admin || checkRole(role) === ROLE.Manager);
  }
  export const adminCondition=()=>{

    // return checkRole(role) === ROLE.Admin?true:false ;
    return true ;
  }
//   const adminManagerReceptionistCondition=()=>{

//     return checkRole("admin") === ROLE.Admin || checkRole("admin") === ROLE.Manager || checkRole("admin") === ROLE.Receptionist;
//   }




const menus = [
    
    // {

    //     label: 'home',
    //     type: "section",
    //     children: [
    //         {
    //             uri: "/",
    //             label: 'Dashboard',
    //             type: "nav-item",
    //             visible: true,
    //             icon: <DashboardIcon sx={{ fontSize: 20 }} />,
    //         },
    //         // {
                
    //         //     uri: "/order-list",
    //         //     label: 'Orders List',
    //         //     type: "nav-item",
    //         //     visible: true,
    //         //     icon: <FormatListNumberedRtlOutlinedIcon sx={{ fontSize: 20 }} />

    //         // },
    //         // {
    //         //     uri: "/orders",
    //         //     label: 'Orders',
    //         //     type: "nav-item",
    //         //     icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />
    //         // },
    //     ]

    // },

    adminManagerCondition()&&{
        label: 'modules',
        type: "section",
        children: [
              // {
            //     uri: "/courses",
            //     label: 'Course',
            //     type: "nav-item",
            //     icon: <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            {
                uri: "/hotels",
                label: 'Hotel',
                type: "nav-item",
                visible: true,
                icon: <RoomServiceIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/sports",
                label: 'Sport',
                type: "nav-item",
                visible: true,
                icon: <KayakingIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/regions",
                label: 'Region',
                type: "nav-item",
                visible: true,
                icon: <SouthAmericaIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/airports",
                label: 'Airport',
                type: "nav-item",
                visible: true,
                icon: <LocalAirportIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/prices",
                label: 'Prices',
                type: "nav-item",
                visible: true,
                icon: <PriceChangeIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/countries",
                label: 'Country',
                type: "nav-item",
                 visible: true,

                icon: <PublicIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/cities",
                label: 'City',
                type: "nav-item",
                 visible: true,

                icon: <LocationCityIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/pages",
                label: 'Page',
                type: "nav-item",
                visible: true,

                icon: <PagesIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/references",
                label: 'Reference',
                type: "nav-item",
                visible: true,

                icon: <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/leads",
                label: 'Lead',
                type: "nav-item",
                visible: true,

                icon: <LeaderboardIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/gallery",
                label: 'Gallery',
                type: "nav-item",
                visible: true,

                icon: <CollectionsIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/chats",
                label: 'Chat',
                type: "nav-item",
                visible: true,

                icon: <ChatOutlinedIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/lead-services-type",
                label: 'Service Type',
                type: "nav-item",
                visible: true,

                icon: <ChecklistIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/lead-services",
                label: 'Lead Services',
                type: "nav-item",
                visible: true,

                icon: <MiscellaneousServicesIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/lead-contract-conditions-type",
                label: 'Condition Type',
                type: "nav-item",
                visible: true,

                icon: <HvacIcon sx={{ fontSize: 20 }} />
            },
          
            {
                uri: "/lead-contract-conditions",
                label: 'Lead Conditions',
                type: "nav-item",
                visible: true,

                icon: <HeatPumpIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/faqs",
                label: 'FAQs',
                type: "nav-item",
                visible: true,

                icon: <QuestionAnswerIcon sx={{ fontSize: 20 }} />
            },
            // {
            //     uri: "/sizes",
            //     label: 'Size',
            //     type: "nav-item",
            //     visible: true,

            //     icon: <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            // {
            //     uri: "/orders",
            //     label: 'Orders',
            //     type: "nav-item",
            //     visible: true,

            //     icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />
            // },
           

        ]

    },
    adminManagerCondition()&&{

        label: 'authPages',
        type: "section",
        children: [
           
            // {
            //     uri: "/restaurants",
            //     label: 'Restaurant',
            //     type: "nav-item",
            //     visible: true,
            //     icon: <AppsIcon sx={{ fontSize: 20 }} />,
            //     is_super:true,

            // },
            // {
            //     uri: "/restaurant-info",
            //     label: 'Restaurant Info',
            //     type: "nav-item",
            //     visible: adminManagerCondition(),
            //     icon: <ImportContactsOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            // {
            //     uri: "/restaurant-time",
            //     label: 'Restaurant Timing',
            //     type: "nav-item",
            //     visible: adminManagerCondition(),
            //     icon: <AccessTimeOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            {
                uri: "/admins",
                label: 'Admin',
                type: "nav-item",
                visible: adminCondition(),
                icon: <SupervisorAccountOutlinedIcon sx={{ fontSize: 20 }} />,
                is_super:true
            },
            {
                uri:"/users",
                label: 'User',
                type: "nav-item",
                visible: adminCondition(),
                icon: <GroupOutlinedIcon sx={{ fontSize: 20 }} />
            },
        ]

    },

];



export default menus;



    