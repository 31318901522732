import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { Button, TextField, Fab, Box } from "@mui/material";
import {
  List,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  InputAdornment,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import {
  selectedLeadSelector,
  setSelectedLead,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useGetAllRegionsQuery,
  useAddLeadMutation,
  useGetLeadQuery,
  useUpdateLeadMutation,
  useGetAllLeadsQuery,
  useGetAllSportsQuery,
  useGetAllCountriesQuery,
  useGetAllCitiesQuery,
  useGetAllLeadUsersQuery,
  useGetAllAirportsQuery,
  useGetAllHotelsQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";

import DzTextEditorMultilingual from "@jumbo/dz-components/TextEditor/DzTextEditorMultilingual";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateLead = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedLead = useSelector(selectedLeadSelector);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const lead_types = ["direct", "free_lead"];
  const lead_status = ["waiting", "pending", "success", "rejected", "deleted"];

  const {
    data: res_get,
    isLoading: isLoadingLead,
    error: errorLead,
  } = useGetLeadQuery(selectedLead?.id);

  const [updateLead, responseUpdate] = useUpdateLeadMutation();
  const [addLead, responseAdd] = useAddLeadMutation();
  const bluePrint = BLUE_PRINTS.lead;
  const { fnShowSnackBar } = useSnackBarManager();

  const { data: res_get_all_regions } = useGetAllRegionsQuery();
  const { data: res_get_all_sports } = useGetAllSportsQuery();
  const { data: res_get_all_countries } = useGetAllCountriesQuery();
  const { data: res_get_all_cities } = useGetAllCitiesQuery();
  const { data: res_get_all_lead_users } = useGetAllLeadUsersQuery();
  const { data: res_get_all_airports } = useGetAllAirportsQuery();
  const { data: res_get_all_hotels } = useGetAllHotelsQuery();

  const [leadState, setLeadState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);

  const handleFromDateChange = (fromValue) => {
    const selectedDate = dayjs(fromValue).format("YYYY-MM-DD");
    setLeadState({
      ...leadState,
      from_date: selectedDate,
    });
  };

  const handleToDateChange = (toValue) => {
    const selectedDate = dayjs(toValue).format("YYYY-MM-DD");
    setLeadState({
      ...leadState,
      to_date: selectedDate,
    });
  };

  const addUpdateLeadData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.lead?.id);

    formData.append("lead_type", leadState.lead_type);
    formData.append("region_id", leadState.region_id);
    formData.append("sport_id", leadState.sport_id);
    formData.append("airport_id", leadState.airport_id);
    formData.append("hotel_id", leadState.hotel_id);
    formData.append("club_name", leadState.club_name);
    formData.append("country_id", leadState.country_id);
    formData.append("city_id", leadState.city_id);
    formData.append("status", leadState.status);
    formData.append("no_guests", leadState.no_guests);
    formData.append("budget", leadState.budget);
    formData.append("comments", JSON.stringify(leadState.comments));
    formData.append("from_date", leadState.from_date);
    formData.append("to_date", leadState.to_date);
    formData.append("lead_user_id", leadState.lead_user_id);
    formData.append("first_name", leadState.first_name);
    formData.append("last_name", leadState.last_name);
    formData.append("email", leadState.email);
    formData.append("mobile", leadState.mobile);

    // return false;
    const res =
      action === "add" ? await addLead(formData) : await updateLead(formData);

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };
  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setLeadState(
      selectedLead
        ? {
            ...res_get?.data?.lead,
            first_name: res_get?.data?.lead?.lead_user?.first_name,
            last_name: res_get?.data?.lead?.lead_user?.last_name,
            email: res_get?.data?.lead?.lead_user?.email,
            mobile: res_get?.data?.lead?.lead_user?.mobile,
          }
        : { ...bluePrint, sport_id: res_get?.data?.lead?.sport_id }
    );
  }, [res_get]);

  const regions = res_get_all_regions?.data?.regions;
  const sports = res_get_all_sports?.data?.sports?.map((sport) =>
    BLUE_PRINTS.sport.translate(sport, selectedLanguage)
  );

  const countries = res_get_all_countries?.data?.countries?.map((country) =>
    BLUE_PRINTS.country.translate(country, selectedLanguage)
  );
  const airports = res_get_all_airports?.data?.region_airports;
  // const selectedCities = res_get_all_cities?.data?.cities?.filter((city) => city?.country_id == leadState?.country_id);

  let selectedCities;
  let selectedCountries;
  let selectedHotels;
  let selectedAirports;

  selectedCities = (res_get_all_cities?.data?.cities || [])
    ?.filter((city) => city?.country_id == leadState?.country_id)
    ?.map((city) => BLUE_PRINTS.city.translate(city, selectedLanguage));
  selectedCountries = (res_get_all_countries?.data?.countries || [])
    ?.filter((country) =>
      country?.region_countries?.some(
        (region_country) => region_country.region_id == leadState?.region_id
      )
    )
    ?.map((country) =>
      BLUE_PRINTS.country.translate(country, selectedLanguage)
    );
  selectedHotels = (res_get_all_hotels?.data?.hotels || [])?.filter((hotel) =>
    hotel?.region_hotels?.some(
      (region_hotel) => region_hotel.region_id == leadState?.region_id
    )
  );
  selectedAirports = (
    res_get_all_airports?.data?.region_airports || []
  )?.filter((airport) => airport.region_id == leadState?.region_id);

  // useEffect(() => {

  //   // const hotels = (res_get_all_hotels?.data?.hotels || []);
  //   // console.log('Initial hotels list:', hotels);

  //   // const filteredHotels = hotels.filter(hotel => {
  //   //   const hasRegion = hotel?.region_hotels?.some(region_hotel => {
  //   //     const match = region_hotel.region_id == leadState?.region_id;
  //   //     console.log(`Checking hotel ${hotel.id} with region hotel ${region_hotel.id}: ${match}`);
  //   //     return match;
  //   //   });
  //   //   console.log(`Hotel ${hotel.id} matches: ${hasRegion}`);
  //   //   return hasRegion;
  //   // });

  //   // console.log('Filtered hotels:', filteredHotels);

  //   selectedCountries = (res_get_all_countries?.data?.countries || [])?.filter(country => country?.region_countries?.some(region_country => region_country.region_id == leadState?.region_id))?.map(country => BLUE_PRINTS.country.translate(country, selectedLanguage));
  //   selectedHotels = (res_get_all_hotels?.data?.hotels || [])?.filter(hotel => hotel?.region_hotels?.some(region_hotel => region_hotel.region_id == leadState?.region_id));
  //   selectedAirports = (res_get_all_airports?.data?.region_airports || [])?.filter(airport => airport.region_id == leadState?.region_id);

  //   console.log('selectedCountries', selectedCountries);
  //   console.log('selectedHotels', selectedHotels);
  //   console.log('selectedAirports', selectedAirports);

  // }, [leadState?.region_id])

  const hotels = res_get_all_hotels?.data?.hotels;
  // const lead_users = res_get_all_lead_users?.data?.lead_users;

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {leadState && hotels ? (
        <div style={addUpdateDivStyleGlobal}>
          <Grid container spacing={3.75}>
            {selectedLead?.id && (
              <Grid item xs={12} md={12} lg={12}>
                <Div sx={{ mb: 0.5, mt: 1 }}>
                  <DZSimpleDropDown
                    placeholder={"Lead Status"}
                    value={leadState?.status || ""}
                    data={lead_status}
                    onChange={(e) => {
                      setLeadState((x) => ({ ...x, status: e.target.value }));
                    }}
                  />
                </Div>
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZSimpleDropDown
                  placeholder={"Select Lead Type"}
                  value={leadState?.lead_type || ""}
                  data={lead_types}
                  onChange={(e) => {
                    setLeadState((x) => ({ ...x, lead_type: e.target.value }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZDropdown
                  placeholder={"Select Region"}
                  fieldTextName={"title"}
                  value={leadState?.region_id || ""}
                  data={regions}
                  onChange={(e) => {
                    setLeadState((x) => ({ ...x, region_id: e.target.value }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZDropdown
                  placeholder={"Select Sport"}
                  value={leadState?.sport_id || ""}
                  data={sports}
                  onChange={(e) => {
                    setLeadState((x) => ({ ...x, sport_id: e.target.value }));
                  }}
                />
              </Div>
            </Grid>
            {/*  */}
            {leadState?.lead_type == "direct" ? (
              <>
                <Grid item xs={12} md={6} lg={6}>
                  <Div sx={{ mb: 0.5, mt: 1 }}>
                    <DZDropdown
                      placeholder={"Select Airport"}
                      value={leadState?.airport_id || ""}
                      data={selectedAirports}
                      onChange={(e) => {
                        setLeadState((x) => ({
                          ...x,
                          airport_id: e.target.value,
                        }));
                      }}
                    />
                  </Div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Div sx={{ mb: 0.5, mt: 1 }}>
                    <DZDropdown
                      placeholder={"Select Hotel"}
                      value={leadState?.hotel_id || ""}
                      data={selectedHotels}
                      onChange={(e) => {
                        setLeadState((x) => ({
                          ...x,
                          hotel_id: e.target.value,
                        }));
                      }}
                    />
                  </Div>
                </Grid>
              </>
            ) : (
              ""
            )}
            {/*  */}
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.club_name}
                  label="Club Name"
                  variant="outlined"
                  value={leadState?.club_name}
                  onChange={(e) => {
                    setLeadState((x) => ({ ...x, club_name: e.target.value }));
                  }}
                  placeholder="Club Name"
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZDropdown
                  placeholder={"Select Country"}
                  label="Select Country"
                  value={leadState?.country_id || ""}
                  data={selectedCountries}
                  onChange={(e) => {
                    setLeadState((x) => ({ ...x, country_id: e.target.value }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZDropdown
                  placeholder={"Select City"}
                  value={leadState?.city_id || ""}
                  data={selectedCities}
                  onChange={(e) => {
                    setLeadState((x) => ({ ...x, city_id: e.target.value }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  fullWidth
                  label="Guests"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  variant="outlined"
                  noOfRows={1}
                  {...(error?.no_guests && {
                    error: true,
                    helperText: error?.no_guests,
                  })}
                  value={leadState?.no_guests}
                  onChange={(e) => {
                    setLeadState((x) => ({ ...x, no_guests: e.target.value }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  fullWidth
                  label="Budget"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">DKK</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  noOfRows={1}
                  value={leadState?.budget}
                  onChange={(e) => {
                    setLeadState((x) => ({ ...x, budget: e.target.value }));
                  }}
                />
              </Div>
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6} lg={6}>
                <DatePicker
                  label="From Date"
                  {...(error?.from_date && {
                    error: true,
                    helperText: error?.from_date,
                  })}
                  defaultValue={dayjs(leadState?.from_date)}
                  inputFormat="YYYY-MM-DD"
                  onChange={handleFromDateChange}
                  slotProps={{ textField: { fullWidth: true, error: false } }}
                />
                {error?.from_date && (
                  <p style={{ color: "red" }}>Please Select Date</p>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <DatePicker
                  label="To Date"
                  defaultValue={dayjs(leadState?.to_date)}
                  inputFormat="YYYY-MM-DD"
                  onChange={handleToDateChange}
                  slotProps={{ textField: { fullWidth: true, error: false } }}
                />
              </Grid>
            </LocalizationProvider>

            <Grid item xs={12} md={12} lg={12}>
              <DzTextEditorMultilingual
                data={leadState?.comments?.[selectedLanguage]}
                onChange={(newContent) => {
                  setLeadState((x) => ({
                    ...x,
                    comments: { ...x.comments, [selectedLanguage]: newContent },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <input
                  type="hidden"
                  value={leadState?.lead_user_id}
                  onChange={(e) => {
                    setLeadState((x) => ({
                      ...x,
                      lead_user_id: e.target.value,
                    }));
                  }}
                />
                <DZTextValidation
                  error={error?.first_name}
                  label="First Name"
                  variant="outlined"
                  value={leadState?.first_name}
                  onChange={(e) => {
                    setLeadState((x) => ({ ...x, first_name: e.target.value }));
                  }}
                  placeholder="First Name"
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.last_name}
                  label="Last Name"
                  variant="outlined"
                  value={leadState?.last_name}
                  onChange={(e) => {
                    setLeadState((x) => ({ ...x, last_name: e.target.value }));
                  }}
                  placeholder="Last Name"
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <DZTextValidation
                error={error?.email}
                label="E-mail"
                variant="outlined"
                value={leadState?.email}
                onChange={(e) => {
                  setLeadState((x) => ({ ...x, email: e.target.value }));
                }}
                placeholder="E-mail"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <DZTextValidation
                error={error?.mobile}
                label="Telephone"
                variant="outlined"
                value={leadState?.mobile}
                onChange={(e) => {
                  setLeadState((x) => ({ ...x, mobile: e.target.value }));
                }}
                placeholder="Telephone"
              />
            </Grid>
          </Grid>
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedLead(null));
              }}
            >
              Cancel
            </Button>
            {selectedLead?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedLead(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateLead;
