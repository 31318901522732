import React from "react";

import Admin from "../pages/admin";
import User from "../pages/user";
import Login2 from "../pages/auth/login2/index"
import Dashboard from "app/pages/dashboard/Dashboard";
import Login from './../pages/auth/login/Login';
// import UserProfile from './../pages/users/user-profile/UserProfile';
import ForgotPassword from './../pages/auth/forgot-password/ForgotPassword';
import ForgotPasswordCode from './../pages/auth/forgot-password-code/ForgotPasswordCode';
import ChangePassword from './../pages/auth/forgot-password-code/ChangePassword';
import Profile from './../pages/profile/index';
import CategoryList from './../pages/category/index';
import ProductList from './../pages/product/index';
import AccessoryList from './../pages/accessory/index';
import RestaurantList from './../pages/restaurant/index';
import UpdateViewRestaurantInfo from './../pages/restaurant-info-time/UpdateViewRestaurantInfo';
import UpdateViewRestaurantTime from './../pages/restaurant-info-time/UpdateViewRestaurantTime';
import SizeGroupList from "app/pages/size_group";
import SizeList from "app/pages/size";
import LeadList from "app/pages/lead";

import ProductAccessoriesList from './../pages/product_accessory/index';
import OrderList from './../pages/order Sequence/index';
import Orders from './../pages/order/index';
import { adminCondition, adminManagerCondition } from "app/layouts/shared/sidebars/Sidebar/menus";
import SportList from "app/pages/sport";
import AirportList from "app/pages/airport";
import CountryList from "app/pages/country";
import CityList from "app/pages/city";
import PageList from "app/pages/pages";
import ReferenceList from "app/pages/references";
import HotelList from "app/pages/hotel";
import AddUpdateHotel from "app/pages/hotel/AddUpdateHotel";
import HotelSportList from "app/pages/hotel_sport";
import LeadOfferList from "app/pages/lead_offer";
import RegionList from "app/pages/regions";
import PricesLists from "app/pages/prices";
import GalleryList from "app/pages/gallery";
import LeadServiceList from "app/pages/lead-service";
import LeadContractConditionList from "app/pages/lead-contract-condition";
import ChatApp from "app/pages/chat/ChatApp";
import FAQS from "app/pages/faqs";
import ServiceType from "app/pages/lead-service-type";
import ContractType from "app/pages/contract-condition-type";

const routes = [

    { path: "/", element: <Login /> },
    { path: "forgot-password",element: <ForgotPassword />  },
    { path: "verify-forgot-password", element: <ForgotPasswordCode /> },
    { path: "change-password", element: <ChangePassword />},
    { path: "/login", element: <Login2 />},
    { path: "/profile",element: <Profile />},
    { path: "/dashboard",  element: <Dashboard />},
    { path: "/order-list",element: <OrderList /> },
    adminManagerCondition()&&{ path: "/sports",element: <SportList />},
    adminManagerCondition()&&{ path: "/regions",element: <RegionList />},
    adminManagerCondition()&&{ path: "/airports",element: <AirportList />},
    adminManagerCondition()&&{ path: "/prices",element: <PricesLists />},
      adminManagerCondition()&& { path: "/countries",element: <CountryList />},
      adminManagerCondition()&&{ path: "/cities", element: <CityList />},
      adminManagerCondition()&&{ path: "/pages", element: <PageList /> },
      adminManagerCondition()&&{ path: "/references",element: <ReferenceList />},
      adminManagerCondition()&&{ path: "/hotels",element: <HotelList />},
      adminManagerCondition()&&{ path: "/hotels/new-hotel",element: <AddUpdateHotel />},
      adminManagerCondition()&&{ path: "/hotel/sports",element: <HotelSportList />},
      adminManagerCondition()&&{ path: "/lead/lead-offers",element: <LeadOfferList />},
      // adminManagerCondition()&&{ path: "/sizes", element: <SizeList /> },
      adminManagerCondition()&&{ path: "/leads", element: <LeadList /> },
      adminManagerCondition()&&{ path: "/gallery", element: <GalleryList/> },
      adminManagerCondition()&&{ path: "/lead-services-type", element: <ServiceType/> },
      adminManagerCondition()&&{ path: "/lead-services", element: <LeadServiceList/> },
      adminManagerCondition()&&{ path: "/lead-contract-conditions-type", element: <ContractType/> },
      adminManagerCondition()&&{ path: "/lead-contract-conditions", element: <LeadContractConditionList/> },
      adminManagerCondition()&&{ path: "/chats", element: <ChatApp/> },
      adminManagerCondition()&&{ path: "/faqs", element: <FAQS/> },
      // adminManagerCondition()&&{ path: "/restaurants", element: <RestaurantList />},
      // adminManagerCondition()&&{ path: "/restaurants",element: <RestaurantList />},
      // adminManagerCondition()&&{ path: "/restaurant-info",element: <UpdateViewRestaurantInfo />},
      // adminManagerCondition()&&{ path: "/restaurant-time", element: <UpdateViewRestaurantTime /> },
      adminCondition()&&{path: "/admins", element: <Admin /> },
      adminCondition()&& { path: "/users",element: <User />},
];

export default routes;